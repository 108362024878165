export default function GraphicDesign({ elementRef }) {
	return (
		<section ref={elementRef} className='slide flex-col py-8'>
			<div className='pb-6'>
				<h3>Graphic Design</h3>
				<p className='pb-3'>
					A collection showcasing a few examples of design work I&rsquo;ve done
					in my professional career.
				</p>
				<hr className='h-px border-0 bg-[#43493A]/30' />
			</div>
			<div className='portfolio-container'>
				<Project img={'wud-journal.jpg'} alt={''} />
				<Project img={'vo-cred.jpg'} alt={''} />
				<Project img={'pf-gc-sc.jpg'} alt={''} />
				<Project img={'pt-certif.jpg'} alt={''} />
				<Project img={'pbcwud-backdrop.jpg'} alt={''} />
				<Project img={'fau-bf-ad.jpg'} alt={''} />
				<Project img={'rdm-graze.jpg'} alt={''} />
				<Project img={'cf-scholar.jpg'} alt={''} />
				<Project img={'fau-highlight.jpg'} alt={''} />
				<Project img={'place-of-hope.jpg'} alt={''} />
				<Project img={'hs-invite.jpg'} alt={''} />
			</div>
		</section>
	)
}

function Project({ img, alt }) {
	return (
		<div className='portfolio-item'>
			<img src={`/assets/imgs/graphic-design/${img}`} alt={alt} />
		</div>
	)
}
