import { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'

export default function Contact({ elementRef }) {
	const [formSent, setFormSent] = useState(false)
	const [sending, setSending] = useState(false)

	const form = useRef(null)

	const sendEmail = e => {
		e.preventDefault()
		setSending(true)

		emailjs
			.sendForm(
				'service_shujyvu',
				'template_f0funee',
				form.current,
				'WQFUEDIy5ehOUw8Rx'
			)
			.then(
				result => {
					console.log(result.text)
					setFormSent(true)
					setSending(false)
				},
				error => {
					console.log(error.text)
					alert(
						`An error has occured. Please try again later.\nError Message: ${error.text}`
					)
					setSending(false)
				}
			)
	}

	const isNumericInput = event => {
		const key = event.keyCode
		return (
			(key >= 48 && key <= 57) || // Allow number line
			(key >= 96 && key <= 105) // Allow number pad
		)
	}

	const isModifierKey = event => {
		const key = event.keyCode
		return (
			event.shiftKey === true ||
			key === 35 ||
			key === 36 || // Allow Shift, Home, End
			key === 8 ||
			key === 9 ||
			key === 13 ||
			key === 46 || // Allow Backspace, Tab, Enter, Delete
			(key > 36 && key < 41) || // Allow left, up, right, down
			// Allow Ctrl/Command + A,C,V,X,Z
			((event.ctrlKey === true || event.metaKey === true) &&
				(key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
		)
	}

	const enforceFormat = event => {
		// Input must be of a valid number format or a modifier key, and not longer than ten digits
		if (!isNumericInput(event) && !isModifierKey(event)) {
			event.preventDefault()
		}
	}

	const formatToPhone = event => {
		if (isModifierKey(event)) {
			return
		}

		const target = event.target
		const input = event.target.value.replace(/\D/g, '').substring(0, 10) // First ten digits of input only
		const zip = input.substring(0, 3)
		const middle = input.substring(3, 6)
		const last = input.substring(6, 10)

		if (input.length > 6) {
			target.value = `(${zip}) ${middle} - ${last}`
		} else if (input.length > 3) {
			target.value = `(${zip}) ${middle}`
		} else if (input.length > 0) {
			target.value = `(${zip}`
		}
	}

	return (
		<>
			{sending && <div className='sending'></div>}
			<section className='slide flex-col py-8 relative' ref={elementRef}>
				<div className='pb-10'>
					<h3>Contact</h3>
					<p className='pb-3'>Let&rsquo;s get in touch.</p>
					<hr className='h-px border-0 bg-[#43493A]/30' />
				</div>
				<div>
					{!formSent ? (
						<form
							ref={form}
							onSubmit={sendEmail}
							autoComplete='off'
							className='contact-form'
						>
							<div className='space-y-4'>
								<input
									name='name'
									placeholder='Name'
									type='text'
									autoComplete='off'
									required
								/>
								<input
									name='email'
									placeholder='Email'
									type='email'
									autoComplete='off'
									required
								/>
								<input
									name='phone'
									placeholder='Phone Number'
									type='tel'
									autoComplete='off'
									onKeyDown={enforceFormat}
									onKeyUp={formatToPhone}
								/>
							</div>
							<textarea
								name='message'
								placeholder='What can I help you with?'
								required
							/>
							<div
								className='col-span-2 md:pt-14 pt-4'
								style={{ margin: 'auto' }}
							>
								<button className='btn'>Submit</button>
							</div>
						</form>
					) : (
						<diiv>
							<h2 className='text-2xl font-medium'>
								Thank you for reaching out!
							</h2>
							<p>I&rsquo;ll get back to you as soon as I can.</p>
						</diiv>
					)}
				</div>
				<footer className='footer'>
					<hr className='h-px border-0 bg-[#43493A]/30 col-span-3 mb-3' />
					<div>
						<h5>Ronny Victor</h5>
						<p>Graphic Designer & Web Developer</p>
					</div>
					<div>
						<h5>Contact</h5>
						<p>
							<a href='mailto:ronnyvictor26@gmail.com'>
								ronnyvictor26@gmail.com
							</a>
						</p>
					</div>
					<div>
						<h5>Based In</h5>
						<p>Las Vegas, Nevada USA</p>
					</div>
				</footer>
			</section>
		</>
	)
}
