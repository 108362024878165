import { useRef } from 'react'

import NavBar from './components/NavBar'
import GraphicDesign from './components/GraphicDesign'
import Title from './components/Title'
import Contact from './components/Contact'
import WebProjects from './components/WebProjects'

export default function App() {
	const scrollElements = {
		home: useRef(null),
		graphicDesign: useRef(null),
		web: useRef(null),
		contact: useRef(null),
	}

	const executeScroll = ref =>
		ref.current.scrollIntoView({ behavior: 'smooth' })

	return (
		<>
			<NavBar scrollElements={scrollElements} executeScroll={executeScroll} />
			<main className='scroller'>
				<Title scrollElements={scrollElements} executeScroll={executeScroll} />
				<GraphicDesign elementRef={scrollElements.graphicDesign} />
				<WebProjects elementRef={scrollElements.web} />
				<Contact elementRef={scrollElements.contact} />
			</main>
		</>
	)
}
