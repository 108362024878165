import StarOutline from '../assets/svgs/StarOutline'
import Star from '../assets/svgs/Star'

import useOnScreen from '../hooks/useOnScreen'

export default function NavBar({ executeScroll, scrollElements }) {
	const { home, graphicDesign, web, contact } = scrollElements

	return (
		<ul className='flex flex-col fixed right-2 md:right-5 top-1/2 -translate-y-1/2 space-y-2 z-10'>
			<NavButton name='Home' section={home} executeScroll={executeScroll} />
			<NavButton
				name='Graphic Design'
				section={graphicDesign}
				executeScroll={executeScroll}
			/>
			<NavButton
				name='Web Development'
				section={web}
				executeScroll={executeScroll}
			/>
			<NavButton
				name='Contact'
				section={contact}
				executeScroll={executeScroll}
			/>
		</ul>
	)
}

function NavButton({ section, executeScroll, name }) {
	return (
		<li>
			<button aria-label={`${name}`} onClick={() => executeScroll(section)}>
				{useOnScreen(section) ? <Star /> : <StarOutline />}
			</button>
		</li>
	)
}
