export default function WebProjects({ elementRef }) {
	return (
		<section ref={elementRef} className='slide flex-col py-8'>
			<div className='pb-6'>
				<h3>Web Development</h3>
				<p className='pb-3'>
					Explore a few of my web creations that bring fun and functionality
					together.
				</p>
				<hr className='h-px border-0 bg-[#43493A]/30' />
			</div>
			<div className='web-project-container'>
				<WebProject
					name={'Sudoku'}
					url={'https://sudoku-rv.web.app/'}
					img={'sudoku.png'}
					desc={
						'Experience my unique interpretation of the timeless puzzle phenomenon, bringing you the ultimate blend of strategy and entertainment.'
					}
				/>
				<WebProject
					name={'Minesweeper'}
					url={'https://zensweeper.web.app/'}
					img={'zensweeper.png'}
					desc={
						'Immerse yourself in a relaxing and addictive game of minesweeper, where tranquility meets the challenge of uncovering hidden treasures.'
					}
				/>
				<WebProject
					name={'What State is This?'}
					url={'https://what-state-rv.web.app/'}
					img={'what-state.png'}
					desc={
						'Embark on a captivating journey to test your knowledge of all 50 states, and discover the thrill of geography like never before.'
					}
				/>
			</div>
		</section>
	)
}

function WebProject({ name, url, img, desc }) {
	return (
		<a href={url} target='_blank' rel='noreferrer noopener'>
			<div
				style={{
					backgroundImage: `linear-gradient(to bottom, rgba(67, 73, 58, 0), rgba(67, 73, 58, 0.85)),url(/assets/imgs/web-development/${img})`,
					transition: 'background-size 0.4s ease-in-out',
				}}
				className='webproject'
			>
				<div className='p-3 lg:p-4'>
					<h4 className='text-white'>{name}</h4>
					<p className='text-white/80 line-clamp-2'>{desc}</p>
				</div>
			</div>
		</a>
	)
}
