import ChevronDown from '../assets/svgs/ChevronDown'

export default function Title({ scrollElements, executeScroll }) {
	return (
		<section
			ref={scrollElements.home}
			className='text-center flex-col justify-center slide relative'
		>
			<h1 className='select-none'>Ronny Victor</h1>
			<h2>Graphic Designer & Web Developer</h2>
			<div className='flex justify-center absolute w-full bottom-8'>
				<button
					onClick={() => executeScroll(scrollElements.graphicDesign)}
					className='w-fit uppercase font-bold text-xl text-[#43493A]/50 hover:text-[#43493A] grid justify-items-center transition-all'
				>
					<div className='pb-1'>Check Out My Work</div>
					<ChevronDown />
				</button>
			</div>
		</section>
	)
}
