import { useState, useEffect, useMemo } from 'react'

export default function useOnScreen(ref) {
	const [isIntersecting, setIsIntersecting] = useState(false)

	const observer = useMemo(
		() =>
			new IntersectionObserver(
				([entry]) => setIsIntersecting(entry.isIntersecting),
				{
					rootMargin: '-50%',
				}
			),
		[]
	)

	useEffect(() => {
		observer.observe(ref.current)

		return () => {
			observer.disconnect()
		}
	}, [ref, observer])

	return isIntersecting
}
